/* eslint-disable */

import lozad from 'lozad';
import ColorThief from '../lib/color-thief.umd';

export default (() => {

  function rgbToHex(r, g, b) {
		if (r > 255 || g > 255 || b > 255)
			throw "Invalid color component";
		return ((r << 16) | (g << 8) | b).toString(16);
	}

	$('.lazy').each((i, el) => {
		var _self = $(el);
		var imgPath = _self.data('background-image-gradient');
		var img = document.createElement("img");
		img.setAttribute("src", imgPath),
			img.addEventListener("load", function () {
				var colorThief = new ColorThief();
				var color1 = '#' + rgbToHex(colorThief.getColor(this)[0], colorThief.getColor(this)[1], colorThief.getColor(this)[2]);
				var color2 = '#' + rgbToHex(colorThief.getPalette(this)[0][0], colorThief.getPalette(this)[0][1], colorThief.getPalette(this)[0][2]);
				_self.parent('[data-vibrant]').css({
					backgroundImage: 'linear-gradient(135deg, ' + color1 + ' 0%,' + color2 + ' 100%)'
				})
			});
	});

	const isIE = typeof document !== 'undefined' && document.documentMode;

	// breakpoints for backgrounds
	const breakpoints = [{
		src: 'sm',
		width: 576
	}];

	// get screen size
	const screen = document.documentElement.clientWidth;

	// observer
	window.lazy = lozad('.lazy', {
		threshold: 0.5,
		load: function load(element) {
      const item = element;
			if (item.nodeName.toLowerCase() === 'picture') {
				const img = document.createElement('img');
				if (isIE && item.getAttribute('data-iesrc')) {
					img.src = item.getAttribute('data-iesrc');
				}
				if (item.getAttribute('data-alt')) {
					img.alt = item.getAttribute('data-alt');
				}
				item.appendChild(img);
			}

			if (item.getAttribute('data-src')) {
				item.src = item.getAttribute('data-src');
			}

			if (item.getAttribute('data-srcset')) {
				item.srcset = item.getAttribute('data-srcset');
			}

			if (item.getAttribute('data-background-image')) {
				const breakpoint = breakpoints.filter((el) => {
          let src;
          if (screen >= el.width && item.getAttribute(`data-background-image-${ el.src }`)) {
           ({ src } = el);
          }
          return src;
        }).pop();

				let attr = item.getAttribute('data-background-image');

				if (breakpoint) {
					attr = item.getAttribute(`data-background-image-${  breakpoint.src}`);
				}

				item.style.backgroundImage = `url(${  attr  })`;
			}

			if (item.getAttribute('data-toggle-class')) {
				item.classList.toggle(item.getAttribute('data-toggle-class'));
			}
		},
		loaded(item) {
			setTimeout(() => {
				item.classList.add('loaded');
				$(item).parent().addClass('hide-gradient');
			}, 200);
		}
	});

	window.lazy.observe();
})();
