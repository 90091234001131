/* eslint-disable */

export default (() => {
  function arrayboolean(arr) {
    for(let item of arr) {
      if(!item) {
        return false;
      }
    }
    return true
  }
  let arrayValidsCheckBox = []
  $('.js-filter-all-checkbox input').on('click', function () {
    $('.js-filter-checkbox input:checkbox').not(this).prop('checked', this.checked);
    arrayValidsCheckBox.length = 0;
    $('.js-filter-checkbox input:checkbox').each(function() {
      arrayValidsCheckBox.push($(this).prop('checked'));
    })
  });
  $('.js-filter-checkbox input:checkbox').change(function() {
    arrayValidsCheckBox.length = 0;
    $('.js-filter-checkbox input:checkbox').each(function() {
      arrayValidsCheckBox.push($(this).prop('checked'));
    })
    if(arrayboolean(arrayValidsCheckBox)) {
      $('.js-filter-all-checkbox input').prop('checked', this.checked)
    } else {
      $('.js-filter-all-checkbox input').prop('checked', false)
    }
  });
	$('.address__list_btn').on('click', function () {
    $(this).toggleClass('is--open')
		$('.address__list').toggleClass('address__list_open');
	});
  $('.input-group-clear').on('click', function () {
    $(this).closest('.input-group-search-club').find('input').val('')
	});
  $('.halyk__club_info_toggle').on('click', function () {
    $(this).closest('.halyk__club_info').toggleClass('is--open')
	});
  $('.js-btn-main-map').on('click', function () {
    $('.main__map_content').toggleClass('map--open')
  });

  window.affixClubInfo = function() {
    $('.object__content_head').affix({
      offset: {
        top: function () {
          var rectTop = document.querySelector('.categories__content').getBoundingClientRect().top;
          var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
          return rectTop + scrollTop;
        },
        bottom: function () {
          return (this.bottom = $('.footer').outerHeight(true))
        }
      }
    })
  }
  if(!$('.halyk__club_info').length) {
    window.affixClubInfo()
  }
	var pageMap = $('.halyk__club .halyk-club-map');

  if(pageMap.length) {
    let openedElement = '';

    ymaps.ready(() => {
      let $map = document.querySelector('.halyk-club-map'),
          zoom = parseInt($map.getAttribute('data-zoom'), 10),
          list = document.querySelectorAll('.item__address'),
          centerCords = [parseFloat(document.querySelector('.item__address').getAttribute('data-lng')),parseFloat(document.querySelector('.item__address').getAttribute('data-lat'))];

      let myMap = new ymaps.Map($map, {
        center: centerCords,
        zoom,
        controls: ['zoomControl']
      });

      let oldPlacemark = null;

      let customPlacemark = ymaps.templateLayoutFactory.createClass(
        '<div class="placemark"></div>',
        {
          build: function () {
            customPlacemark.superclass.build.call(this)
            let element = this.getParentElement().querySelector('.placemark')
            let size = this.isActive ? 64 : 48
            let shape = {type: 'Rectangle', coordinates: [[-14, -20], [14, 20]]}
            // Зададим фигуру активной области.
            this.getData().options.set('shape', shape)
            if (this.isActive) {
              element.classList.add("active")
              openedElement.classList.add("active")
              document.querySelector('.main__map').querySelector('.item__address').classList.add("active")
            } else if (this.inited) {
              element.classList.remove("active");
              openedElement.classList.remove("active");
              document.querySelector('.main__map').querySelector('.item__address').classList.remove("active")
            }
            if (!this.inited) {
                let count = 0
                this.inited = true;
                this.isActive = false;
                // При клике по метке будем перестраивать макет.
                this.getData().geoObject.events.add('click', function (e) {
                  if(!this.isActive) {
                    // _self.openModal(e.get('target').properties.get('id'))
                    if(oldPlacemark) {
                      oldPlacemark.isActive = false
                      oldPlacemark.rebuild()
                    }
                    oldPlacemark = this
                  } else {
                    oldPlacemark = null
                  }
                  openedElement = e.get('target')['properties'].get('element');
                  document.querySelector('.main__map').querySelector('.item__address').innerHTML = openedElement.innerHTML;
                  this.isActive = !this.isActive
                  this.rebuild();
                }, this);
            }
          }
        }
      )

      list.forEach(element => {
        let placemark = new ymaps.Placemark([parseFloat(element.getAttribute('data-lng')),parseFloat(element.getAttribute('data-lat'))], {
          element: element,
          type: 'Placemark'
        }, {
          iconLayout: customPlacemark,
          hasBalloon: false
        });

        myMap.geoObjects.add(placemark)

      });

    });
  }
})();
