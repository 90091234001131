/* eslint-disable */

export default (() => {
  var $page = $('.page--knowledge-base');

  if(!$page.length) return;

  var accordionParent = $page.find('#accordion');

  $(window).on('load', function () {
      init(accordionParent);
  })

  function init (accordionParent) {
      var accordionItem = accordionParent.find('[data-toggle="collapse"]');
      if(getHash() !== '' && getHash() !== '#!') {
          $(getHash()).collapse('show');
      }
      accordionItem.each(function () {
          var id = $(this).attr('href');
          $(id).on('shown.bs.collapse', function (e){
              let relatedTarget = $(e.target);
              goTo(relatedTarget);
              setHash(id);
          });
          $(id).on('hidden.bs.collapse', function (e){
              setHash('!');
          })
      })
  }

  function goTo (element) {
      var elementOffsetTop = element.offset().top;
      var panelHeight = element.closest('.panel').height();
      $("html, body").stop().animate({ scrollTop:elementOffsetTop - panelHeight / 2 }, 500, 'swing');
  }

  function getHash () {
      return window.location.hash;
  }

  function setHash (hash) {
      window.location.hash = hash;
  }

  $page.find('.btn-like').on('click', function () {
      var _self = $(this);
      var url = _self.data('url');
      var itemFooter = _self.closest('.item__footer');

      $.get(url).done(function (r) {
          if(r.success) {
              itemFooter.find('[data-step="default"]').fadeOut(200, 'swing', function () {
                  itemFooter
                      .find('[data-step="success"]')
                      .css("display", "flex")
                      .hide().fadeIn(200);
              });
          }
      })
  })
})();
