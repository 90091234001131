/* eslint-disable */

export default (() => {

  var formCustomValidation = $('.custom_validation');

  if(!formCustomValidation.length) return;

  formCustomValidation.each(function (i,el) {
  var thisForm = $(el)
  var formControl = thisForm.find('.form-control, .checkbox input'),
      defaultConfig = {
          errorClass: 'has-error',
          formControlContainer: '.form-group',
          formErrorTmpClass: 'form-field-error-label'
      },
      errorTmp = '<div class="'+defaultConfig.formErrorTmpClass+'">:text</div>';

  formControl.each(function () {
      var formName = $(this).attr('name'),
          _self = $(this).get(0);

      var findValidationFormItem = thisForm.find('input[name="validation\['+formName+'\]"]');

      if(!findValidationFormItem.length) return;

      var validationMessages = findValidationFormItem.data('messages'),
          validationRules = findValidationFormItem.data('rule'),
          data = {}, rule = {}, messages = {},
          isCheckbox = $(this).is('[type=checkbox]'),
          checkboxIsChecked = isCheckbox ? $(this).is(':checked') ? true : false : '';

      validationMessages.map(function (item) {
          messages[item.rule] = item.message
      });

      rule[formName] = validationRules.split('|');
      data[formName] = !isCheckbox ? $(this).val() : checkboxIsChecked;

      _self.validator = new Validator(data, rule, messages);
      _self.validator.dirty = false;
      _self.validator.valid = _self.validator.passes() ? true : false;

      $(this).on('input', function () {
        setTimeout(() => {
          var _this = $(this);
          checkboxIsChecked = isCheckbox ? _this.is(':checked') ? true : false : '';
          _self.validator.input[formName] = !isCheckbox ? _this.val() : checkboxIsChecked;
          if(_this.val()) {
              _this.one('blur', function () {
                  _self.validator.dirty = true;
                  _self.validator.fails(function() {
                      _self.valid = true;
                      showError(_this.closest(defaultConfig.formControlContainer), _self.validator.errors.first(formName))
                  });
              });
              if(_self.validator.fails() && _self.validator.dirty) {
                  showError(_this.closest(defaultConfig.formControlContainer), _self.validator.errors.first(formName))
              }
          }
          if(isCheckbox && _self.validator.fails()) {
              showError(_this.closest(defaultConfig.formControlContainer), _self.validator.errors.first(formName))
          }

          _self.validator.fails(function () {
              if(_self.validator.dirty) {
                  showError(_this.closest(defaultConfig.formControlContainer), _self.validator.errors.first(formName))
              }
          });
          _self.validator.fails(function() {
              _self.validator.valid = false
          });
          _self.validator.passes(function() {
              hideError(_this.closest(defaultConfig.formControlContainer));
              _self.validator.valid = true
          });
          checkValidationAll(thisForm);
        },0)
      });
  });
  checkValidationAll(thisForm);

  function validateAll (form) {
      var formControl = form.find('input').not('[type=hidden]');

      formControl.each(function () {
          var _this = $(this),
              _self = $(this).get(0);

          _self.validator.fails(function() {
              showError(_this.closest(defaultConfig.formControlContainer), _self.validator.errors.first(_this.attr('name')))
          });
      });
  }

  function checkValidationAll (form) {
      var formControl = form.find('input').not('[type=hidden]'),
          arrayValids = [];

      formControl.each(function () {
          var _this = $(this),
              _self = $(this).get(0);

          if(_self.validator == undefined) return;

          arrayValids.push(_self.validator.valid);
      });

      form.find('[type=submit]').prop('disabled', arrayValids.includes(false))

  }

  function showError (elem, text) {
      if(!elem.hasClass(defaultConfig.errorClass)) {
          elem.addClass(defaultConfig.errorClass)
          if(elem.find('.'+defaultConfig.formErrorTmpClass).length) {
              elem.find('.'+defaultConfig.formErrorTmpClass).show()
          } else {
              elem.append(errorTmp.replace(':text', text))
          }
      }
  }

  function hideError (elem) {
      if(elem.hasClass(defaultConfig.errorClass)) {
          elem.removeClass(defaultConfig.errorClass)
          if(elem.find('.'+defaultConfig.formErrorTmpClass).length) {
              elem.find('.'+defaultConfig.formErrorTmpClass).hide();
          }
      }
  }

  })
})();
