/* eslint-disable */

import Vue from 'vue'
import { script, getCookie, isDev } from '../lib/utils'

export default(() => {Vue.filter('declOfNum', function (number, titles) {
  if (!number && !titles) return ''
  let cases = [2, 0, 1, 1, 1, 2];
  return `${number}  ${titles[ (number%100>4 && number%100<20)? 2 : cases[(number%10<5)?number%10:5] ]}`;
});

var imagePath = isDev ? '/themes/halyk/assets/images/' : '../images/';
if($('#map-app').length) {
  var mapApp = new Vue({
      el: '#map-app',
      delimiters: ['${', '}'],
      data: {
          activeTab: '1',
          // Экземпляр карты
          map: null,
          // Менеджер объектов для карты
          objectManager: null,
          // Здесь храним точки, не изменяя их.
          storage: [],
          // А здесь изменяем
          markerCollection: [],
          // Строка поиска по адресу
          addressFilter: null,
          // Центр карты по умолчанию
          mapCenter: [43.238293, 76.945465],
          //Проверка на мобильнкую версию
          isMobile: window.matchMedia("(max-width: 768px)").matches,
          //город выбранный пользователем
          city: {},
          //выбранные фильтра
          filterPicked: [],
          loading: true
      },
      created () {
          let urlJson = document.getElementsByClassName('map-module')[0].dataset.json;
          let self = this;
          ymaps.ready(() => {
              $.ajax({
                  url: urlJson != undefined ? urlJson : 'https://api.myjson.com/bins/k1q2s',
                  type: "GET",
                  dataType: "json"
              }).done((r) => {
                  if(r.success) {
                      self.init(r);
                      $('.map__wrap').removeClass('loading');
                  }
              });
          });

      },
      computed: {
          placemarks() {
              let markers = this.markerCollection;
              let _self = this;

              // Фильтруем по строке адреса
              if (this.addressFilter) {
                  let search = this.addressFilter.replace(/([.,-])|\s/gi, "").toLowerCase();
                  markers = markers.filter(marker => {
                      let normalize = marker.address.replace(/([.,-])|\s/gi, "").toLowerCase();
                      return normalize.indexOf(search) !== -1;
                  });
              }

              // И по флагам
              if(this.filterPicked.length && markers.length) {
                  markers = markers.filter(function(x) {
                      var find = null;
                      for(var i = 0; i < _self.filterPicked.length; i++) {
                          find = x.filters != null ? x.filters.indexOf(_self.filterPicked[i]) !== -1 : '';
                          if(!find) return;
                      }
                      return x;
                  });
              }
              return markers;
          },
      },
      watch: {
          placemarks () {
              let self = this;
              try{
              this.objectManager.objects.removeAll();
              setTimeout(()=>{
                  if(Array.isArray(this.placemarks) && this.placemarks.length>0){

                  this.placemarks.forEach(placemark => {
                      let coords = [+placemark.lat.replace(',','.'), +placemark.lng.replace(',','.')];
                      if (isNaN(coords[0]) || isNaN(coords[1])){
                          console.error('Broken placemark coordinates', placemark);
                          return;
                      }
                          this.objectManager.add({
                              type: 'Feature',
                              id: placemark.id,
                              geometry: {
                                  type: 'Point',
                                  coordinates: coords
                              },
                              properties: {
                                  balloonContent: placemark.html,
                                  clusterCaption: `<strong>${placemark.title}</strong>`,
                                  balloonContentHeader: placemark.title,
                                  balloonContentBody: placemark.html,

                              },
                              options: {
                                  iconLayout: 'default#image',
                                  iconImageHref: imagePath+"pic-marker.png",
                                  iconImageSize: [44, 56],
                                  iconImageOffset: [-22, -56],
                                  hideIconOnBalloonOpen: false,
                                  openBalloonOnClick: true,
                                  balloonShadow: false,
                                  balloonLayout: this.MyBalloonLayout,
                                  balloonContentLayout: this.MyBalloonContentLayout,
                                  balloonPanelMaxMapArea: 0
                              },
                              marker: placemark
                          });
                      });
                  }
                  this.map.geoObjects.add(this.objectManager);
                  setTimeout(()=>{
                      if(this.placemarks.length >= 2) {
                          this.map.setBounds(this.map.geoObjects.getBounds(), {
                              useMapMargin: true
                          });
                      } else {
                          if (this.placemarks.length)
                              this.setMapCenter(+this.placemarks[0].lat, +this.placemarks[0].lng, 17, 0);
                      }

                  },100);
              },100);

              }catch(e){
                  console.error(e);
              }
          }
      },
      methods: {
          init: function (data) {
              let self = this;

              this.map = new ymaps.Map("map", {
                  center: [43.238293, 76.945465],
                  zoom: 11,
                  controls: [],
              });
              //Смещение карты при открытие маркеров
              this.map.margin.addArea({
                  left: !this.isMobile ? '20%': 50,
                  top: 50,
                  width: !this.isMobile ? '20%' : 0,
                  height: !this.isMobile ? '100%' : 0
              });

              // Создание макета балуна на основе Twitter Bootstrap.
              this.MyBalloonLayout = ymaps.templateLayoutFactory.createClass(
                  '<div class="popover top">' +
                      '<a class="close" href="#"><i class="icon icon-close"></i></a>' +
                      '<div class="arrow"></div>' +
                      '<div class="popover-inner">' +
                      '$[[options.contentLayout]]' +
                      '</div>' +
                  '</div>', {
                  build: function () {
                      this.constructor.superclass.build.call(this);

                      this._$element = $('.popover', this.getParentElement());

                      this.applyElementOffset();

                      this._$element.find('.close')
                          .on('click', $.proxy(this.onCloseClick, this));
                  },
                  clear: function () {
                      this._$element.find('.close')
                          .off('click');

                      this.constructor.superclass.clear.call(this);
                  },
                  onSublayoutSizeChange: function () {
                      self.MyBalloonLayout.superclass.onSublayoutSizeChange.apply(this, arguments);

                      if(!this._isElement(this._$element)) {
                          return;
                      }

                      this.applyElementOffset();

                      this.events.fire('shapechange');
                  },
                  applyElementOffset: function () {
                      let isTabs = this._$element.find('.popover-tabs').length;
                      this._$element.css({
                          left: -((this._$element[0].offsetWidth / 2) + 5),
                          top: -(this._$element[0].offsetHeight + (!isTabs ? 65 : 35))
                      });

                  },
                  onCloseClick: function (e) {
                      e.preventDefault();

                      this.events.fire('userclose');
                  },
                  getShape: function () {
                      if(!this._isElement(this._$element)) {
                          return MyBalloonLayout.superclass.getShape.call(this);
                      }

                      var position = this._$element.position();

                      return new ymaps.shape.Rectangle(new ymaps.geometry.pixel.Rectangle([
                          [position.left, position.top], [
                              position.left + this._$element[0].offsetWidth,
                              position.top + this._$element[0].offsetHeight + this._$element.find('.arrow')[0].offsetHeight
                          ]
                      ]));
                  },
                  _isElement: function (element) {
                      return element && element[0] && element.find('.arrow')[0];
                  }
              }),

              // Создание вложенного макета содержимого балуна.
              this.MyBalloonContentLayout = ymaps.templateLayoutFactory.createClass(
                  '<div class="popover-content"><strong>$[properties.balloonContentHeader]</strong> $[properties.balloonContent]</div>'
              );

              // Создание вложенного макета содержимого кластера балуна.
              this.MyClusterBalloonContentLayout = ymaps.templateLayoutFactory.createClass(
                  '<div class="popover-content popover-tabs">'+
                          '<ul class="nav nav-pills" role="tablist">'+
                          '{% for index, geoObject in properties.geoObjects %}'+
                              '<li role="presentation"{% if index == 0 %} class="active"{% endif %}>'+
                                  '<a href="#popover-tab-{{index}}" role="tab" data-toggle="tab">{{ geoObject.properties.balloonContentHeader|raw }}</a>'+
                              '</li>'+
                          '{% endfor %}'+
                          '</ul>' +
                          '<div class="tab-content">'+
                              '{% for index, geoObject in properties.geoObjects %}'+
                                  '<div role="tabpanel" class="tab-pane fade{% if index == 0 %} in active{% endif %}" id="popover-tab-{{index}}">'+
                                      '$[geoObject.properties.balloonContentBody|raw ]'+
                                  '</div>'+
                              '{% endfor %}'+
                          '</div>'+
                  '</div>'
              );

              this.objectManager = new ymaps.ObjectManager({
                  clusterize: true,
                  clusterOpenBalloonOnClick: true,
                  clusterHideIconOnBalloonOpen: false,
                  clusterBalloonLayout: this.MyBalloonLayout,
                  clusterBalloonContentLayout: this.MyClusterBalloonContentLayout,
                  clusterBalloonPanelMaxMapArea: 0,
                  clusterIcons: [
                      {
                          href: imagePath+'pic-cluster-marker.png',
                          size: [48, 48],
                          offset: [-24, -24]
                      }
                  ]
              });

              this.storage = data.data;
              this.markerCollection = data.data;

            //   let cityID = getCookie('city_id');
            //   if (cityID) {
            //       this.markerCollection = this.storage.filter(marker => {
            //           return +marker.city.id === +cityID;
            //       });
            //   }

              this.affix();
              this.loading = false;
          },
          affix() {
              let el = $(this.$el).find('.filters__form');
              let top = el.offset().top;
              let offset = 24;
              let width = el.width();

              el.affix({
                  offset: {
                      top: top - offset
                  }
              }).on('affix.bs.affix', function () {
                  el.width(width);
              }).on('affix-top.bs.affix', function () {
                  el.attr('style', '');
              })
          },
          setMapCenter(lat, long, zoom = 17, duration = 500) {
              this.map.panTo([
                  [lat, long]
              ]).then(() => {
                  this.map.setZoom(zoom, {duration: duration});
              });
          },
          onPointClick(point){
              let self = this;
              let coords = [+point.lat.replace(',', '.'), +point.lng.replace(',', '.')];
              if (isNaN(coords[0]) || isNaN(coords[1])){
                  console.error('Broken placemark coordinates', point);
              return;
              } else {
                  this.activeTab = '1';
                  self.map.setCenter(coords, 17, { checkZoomRange: true }).then(function () {
                      self.objectManager.objects.balloon.open(point.id);
                  });
              }
          },
          updateCity(id, lat, long) {
              this.city = {id, lat, long};
              this.markerCollection = this.storage.filter(marker => {
                  return +marker.city_id === id;
              });
              // this.setMapCenter(+lat, +long, 11);
            //   window.updateCity(id, lat, long);
          }
      }
  });

  $(document).on('change', '._js_change_city', function(){
    let index = $(this).prop('selectedIndex');
    let _self = $($(this)[0][index]);
    let val = _self.data('city-id');
    let lat = parseFloat(_self.data('lat'));
    let lng = parseFloat(_self.data('lng'));

    if(!val || !lat || !lng) return;
    mapApp.updateCity(val, lat, lng);
  });

}
})();
