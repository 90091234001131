/* eslint-disable */

export default (() => {
  var header = $('.header');
  var searchBlock = header.find('.search__block');
  var btnSearch = searchBlock.find('.btn-icon-search');
  var btnClose = searchBlock.find('.btn-close');

  btnSearch.on('click', function (e) {
      if(!searchBlock.hasClass('is--visible'))
          e.preventDefault();

      searchBlock.addClass('is--visible');
  });
  btnClose.on('click', function (e) {
      e.preventDefault();
      searchBlock.find('input').val('');
      searchBlock.removeClass('is--visible');
  });
})();
