/* eslint-disable */

import Vue from 'vue';
import XIRR from '../lib/XIRR'
import VueSlider from 'vue-slider-component'

export default (() => {
  if ($('#js_credit_calculator').length) {
    Vue.use(VueNumeric.default)
    var getCreditProducts = window.getCreditProducts;
    var sliderOptionsSum = {
        tooltip: false,
        interval: 5000,
        style: {
            "padding": "0",
            "top": "-2.5px"
        },
        dotSize: 23,
        bgStyle: {
            "backgroundColor": "transparent",
            "borderRadius": "0",
            "height": "3px"
        },

        sliderStyle: [{
                "backgroundColor": "#00805f",
            },
            {
                "backgroundColor": "#00805f",
            }
        ],
        processStyle: {
            "background": "#00805f",
            "borderRadius": "0"
        }
    };
    var sliderOptionsTerm = {
        tooltip: false,
        style: {
            "padding": "0",
            "top": "-2.5px"
        },
        dotSize: 23,
        bgStyle: {
            "backgroundColor": "transparent",
            "borderRadius": "0",
            "height": "3px"
        },

        sliderStyle: [{
                "backgroundColor": "#00805f",
            },
            {
                "backgroundColor": "#00805f",
            }
        ],
        processStyle: {
            "background": "#00805f",
            "borderRadius": "0"
        }
    };
    var creditCalculator = new Vue({
        el: '#js_credit_calculator',
        delimiters: ['${', '}'],
        data: {
            creditStorage: [],
            creditCollection: [],
            typeCalc: 'ANNUITY',
            sliderSum: 0,
            sliderTerm: 0,
            currentSelectedObj: null,
            currentSelectedType: null,
            sliderOptionsTerm: sliderOptionsTerm,
            sliderOptionsSum: sliderOptionsSum,
            creditCategoryId: null,
            creditTypeId: null,
            creditRate: null,
            rateCustom: false,
            ticksLabelSum: [],
            ticksLabelTerm: [],
            separator: 'space',
            resultsVisibility: false,
            percentsTotal: 0,
            managementFee: 0
        },
        methods: {
            init(r) {
                let _self = this;
                this.creditStorage = r;
                this.creditCollection = r;
                this.creditCategoryId = this.creditCollection[0].id;
                setTimeout(() => {
                    $('#credit_type').selectric().on('change', function () {
                        _self.creditCategoryId = +$(this).val();
                    });
                }, 50);
            },
            changeCreditCategory(id) {
                let currentSelectedObj = this.creditCollection.find((obj) => {
                    return obj.id === id;
                });
                this.currentSelectedObj = currentSelectedObj;
                this.creditTypeId = currentSelectedObj.types[0].id;
            },
            isNumber(evt) {
                evt = (evt) ? evt : window.event;
                var charCode = (evt.which) ? evt.which : evt.keyCode;
                if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                    evt.preventDefault();;
                } else {
                    return true;
                }
            },
            result() {
                this.resultsVisibility = true;
            },
            getDetailedMonthAnnuity(firstDay, monthIndex, prevMonthDetails) {
                let theAnualKoef = this.creditRate / 100;

                //Вычислить остаток выплаты за данный месяц
                let thePaymentAmount = (!prevMonthDetails) ?
                    (this.sliderSum * theAnualKoef / 12) / (1 - (Math.pow(1 + theAnualKoef / 12, -this.sliderTerm))) :
                    prevMonthDetails.paymentAmountTotal;


                //Вычислить сумму платежа по основному долгу за данный месяц
                let theDebtPayment = (!prevMonthDetails) ?
                    (thePaymentAmount - this.sliderSum * theAnualKoef * 30 / 360) :
                    (thePaymentAmount - prevMonthDetails.debtRest * theAnualKoef * 30 / 360);


                //Вычислить остаток долга
                let theDebtRest = (!prevMonthDetails) ?
                    (this.sliderSum - theDebtPayment) :
                    (prevMonthDetails.debtRest - theDebtPayment);

                return {
                    date: firstDay,
                    month: monthIndex + 1,
                    debtRest: +theDebtRest.toFixed(2),
                    paymentDebt: +theDebtPayment.toFixed(2),
                    paymentPercents: +(thePaymentAmount - theDebtPayment).toFixed(2),
                    paymentAmountTotal: +thePaymentAmount.toFixed(2),
                };
            },
            getDetailedMonthEqualShares(firstDay, monthIndex, prevMonthDetails) {
                let theAnualKoef = this.creditRate / 100;

                let theDebtPayment = (this.sliderSum / this.sliderTerm),
                    theDebtRest = (!prevMonthDetails) ? (this.sliderSum - theDebtPayment) : (prevMonthDetails.debtRest - theDebtPayment);

                //Вычислить остаток выплаты за данный месяц
                let thePaymentAmount = (!prevMonthDetails) ?
                    (this.sliderSum / this.sliderTerm +
                        this.sliderSum *
                        (this.sliderTerm / (12 / this.sliderTerm * (monthIndex + 1)) - monthIndex + 2) *
                        (theAnualKoef / this.sliderTerm) / this.sliderTerm) :
                    (
                        (this.sliderSum - this.sliderSum / this.sliderTerm * (monthIndex)) *
                        theAnualKoef / 12 + this.sliderSum / this.sliderTerm
                    );

                return {
                    date: firstDay,
                    month: monthIndex + 1,
                    debtRest: +theDebtRest.toFixed(2),
                    paymentDebt: +theDebtPayment.toFixed(2),
                    paymentPercents: +(thePaymentAmount - theDebtPayment).toFixed(2),
                    paymentAmountTotal: +thePaymentAmount.toFixed(2),
                };
            },
            generateDetailed() {
                let items = [];
                this.percentsTotal = 0;

                let theMonth = new Date().getMonth() + 1;
                let theYear = new Date().getFullYear();
                let prevMonthDetails;
                for (let i = 0; i < this.sliderTerm; i++) {
                    if (theMonth > 11) {
                        theYear++;
                        theMonth = 0;
                    }
                    let theMonthFirstDay = new Date(theYear, theMonth, 1);
                    theMonth++;

                    let theMonthDetailed;

                    if (!this.isEqualType)
                        theMonthDetailed = this.getDetailedMonthAnnuity(theMonthFirstDay, i, prevMonthDetails);
                    else theMonthDetailed = this.getDetailedMonthEqualShares(theMonthFirstDay, i, prevMonthDetails);

                    this.percentsTotal += theMonthDetailed.paymentPercents;
                    items.push(theMonthDetailed);
                    prevMonthDetails = theMonthDetailed;
                }
                return items;
            },
        },
        watch: {
            creditCategoryId(val) {
                this.changeCreditCategory(val);
            },
            creditTypeId(val) {
                let currentSelectedType = this.currentSelectedObj.types.find((obj) => {
                    return obj.id === val;
                });
                this.currentSelectedType = currentSelectedType;
                let months = this.currentSelectedType.months;
                this.sliderOptionsSum.max = months[0].max;
                this.sliderSum = this.sliderOptionsSum.value = this.sliderOptionsSum.min = months[0].min;
                this.sliderTerm = this.sliderOptionsTerm.value = this.sliderOptionsTerm.max = months[months.length - 1].month;
                this.sliderTerm = this.sliderOptionsTerm.value = this.sliderOptionsTerm.min = months[0].month;

                this.ticksLabelSum = [months[0].min, months[0].max];
                this.ticksLabelTerm = [months[0].month, months[months.length - 1].month];

                //Проверка на возможность пользовательского ввода процентной ставки
                if (!months[0].rate) {
                    this.rateCustom = true
                    this.creditRate = 10 //default rate
                } else {
                    this.rateCustom = false
                    this.creditRate = this.currentSelectedType.months[0].rate;
                }
            },
            sliderTerm(val) {
                let findRate = this.currentSelectedType.months.find((obj) => {
                    return obj.month >= val;
                });
                if (!this.rateCustom)
                    this.creditRate = findRate.rate;
            }
        },
        computed: {
            isEqualType() {
                return this.typeCalc == 'EQUAL' ? 1 : 0;
            },
            calculate() {
                this.resultsVisibility = false;
                if (!this.currentSelectedType) return;
                //Комиссия может быть указана в виде процентов от суммы или в виде суммы
                //Если значение комиссии больше 100, то это сумма, меньше - процент
                let theManagementFee = this.sliderSum / 100 * this.managementFee;
                let theManagementFeeHidden = this.sliderSum/100*this.currentSelectedType.managementFeeHidden;

                let theResults = {
                    loanAmount: this.sliderSum.toFixed(),
                    loanTerm: this.sliderTerm,
                    annualRate: this.creditRate,
                    paymentAmount: 0,
                    percentsAmount: 0,
                    items: []
                }

                theResults.items = this.generateDetailed();
                theResults.paymentAmount = (this.sliderSum + this.percentsTotal - theManagementFee).toFixed();
                theResults.percentsAmount = this.percentsTotal.toFixed();

                /**
                 * Посчитать годовую эффективную ставку
                 */

                //Получить даты выплат и суммы выплат из расчитанной детальной информации
                let theDates = theResults.items.map(function (x) {
                    return x.date;
                })
                let theValues = theResults.items.map(function (x) {
                    return x.paymentAmountTotal;
                })
                let theToday = new Date();
                let theFistDay = new Date(theToday.getFullYear(), theToday.getMonth(), 1);

                //Добавить выплаты на день получения кредита равны минус сумме займа и комиссии за организацию кредита
                theDates.unshift(theFistDay, theFistDay);
                theValues.unshift(-this.sliderSum + theManagementFeeHidden, -theManagementFeeHidden);

                theResults.annualRateEffective = (XIRR(theValues, theDates, 0.1) * 100).toFixed(1);
                return theResults;
            }
        },
        created() {
            this.init(getCreditProducts);
        },
        components: {
          VueSlider
        }
    })
}
})();
