/* eslint-disable */
export const isDev = process.env.NODE_ENV != 'development';

export const setCookie = (name, value, options) => {
	const config = options || {};

	let { expires } = config;

	if (typeof expires === "number" && expires) {
		const d = new Date();
		d.setTime(d.getTime() + expires * 1000);
    expires = d;
    config.expires = d;
	}
	if (expires && expires.toUTCString) {
		config.expires = expires.toUTCString();
	}

	const val = encodeURIComponent(value);

	let updatedCookie = `${ name }=${ val }`;


  Object.keys(config).forEach((key) => {
    if (Object.prototype.hasOwnProperty.call(config, key)) {
      updatedCookie += `; ${ key}`;
      const propValue = config[key];
      if (propValue !== true) {
        updatedCookie += `=${ propValue }`;
      }
    }
  })
	document.cookie = updatedCookie;
}

// возвращает cookie с именем name, если есть, если нет, то undefined
export const getCookie = (name) => {
	const matches = document.cookie.match(new RegExp(
    // eslint-disable-next-line
		`(?:^|; )${ name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') }=([^;]*)`
	));
	return matches ? decodeURIComponent(matches[1]) : undefined;
}

export const daysInMonth = (month, year) => {
  return new Date(year, month, 0).getDate();
}

export const declOfNum = (number, titles) => {
  const cases = [2, 0, 1, 1, 1, 2];
  return titles[ (number%100>4 && number%100<20)? 2 : cases[(number%10<5)?number%10:5] ];
}

export const format = (value) => {
  return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
}

export const formatAmount = (amount, arr) => {
  if(arr == undefined) {
      arr = [];
  }
  if (amount >= 1000000000) {
      return + parseInt((amount / 1000000000).toFixed(1)) + ' ' + (arr[2] != undefined ? arr[2] : "млрд.");
  }
  if (amount >= 1000000) {
      return + parseInt((amount / 1000000).toFixed(1)) + ' ' + (arr[1] != undefined ? arr[1] : "млн.");
  }
  if (amount >= 1000) {
      return + parseInt((amount / 1000).toFixed(1)) + ' ' + (arr[0] != undefined ? arr[0] :"тыс.");
  }
  return + parseInt(amount.toFixed(1));
}

export const prettyDate = (num, monthesArr, yearsArr) => {
  const yearsPostfixes = (yearsArr || ['год', 'года', 'лет']);
  const monthesPostfixes = (monthesArr || ['месяц', 'месяца', 'месяцев']);
  const monthes = num % 12;
  const years = (num - monthes) / 12;
  if (years === 0) {
      return `${ monthes } ${ declOfNum(monthes, monthesPostfixes) }`;
  }
  if (monthes === 0) {
      return `${ years } ${ declOfNum(years, yearsPostfixes) }`;
  }
  return '';
}

export const script = (url) => {
  if (Array.isArray(url)) {
    const self = this;
    const prom = [];
    url.forEach((item) => {
      prom.push(self.script(item));
    });
    return Promise.all(prom);
  }

  return new Promise((resolve, reject) => {
    let r = false;
    const t = document.getElementsByTagName('script')[0];
    const s = document.createElement('script');
    const readyStateChange = () => {
      if (!r && (!this.readyState || this.readyState === 'complete')) {
        r = true;
        resolve(this);
      }
    };

    s.type = 'text/javascript';
    s.src = url;
    s.async = true;

    s.onload = readyStateChange()
    s.onreadystatechange = readyStateChange()
    s.onerror = reject()
    s.onabort = reject();
    t.parentNode.insertBefore(s, t);
  });
}
