/* eslint-disable */

export default (() => {

  $('.modal-lesing-partners').on('show.bs.modal', function (e){
    var $item = $(e.relatedTarget).closest('.lesing-partners-item'),
        $modal = $(this),
        contentHtml = $item.find('.common-block__content').html();

        $modal.find('.lesing-partners-item').html(contentHtml);
  });

})();
