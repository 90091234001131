/* eslint-disable */

import Vue from 'vue';
import { script, getCookie, isDev } from '../lib/utils'

export default (() => {
  if ($('#partners').length) {
    var bonusPartners = new Vue({
        el: '#partners',
        delimiters: ['${', '}'],
        data: {
            // Здесь храним точки, не изменяя их.
            storage: [],
            // А здесь изменяем
            partnersCollection: [],
            //город выбранный пользователем
            cityId: null,
            cityList: [],
            city: {},
            //выбранные фильтра
            currentCategory: null,
            categoriesList: [],
            // Строка поиска по названию
            searchFilter: null,
            loading: true
        },
        created: function () {
            this.fetchCategories()
                .then(() => {
                    return this.fetchCity()
                })
                .then(() => {
                   return this.fetchItems(this.currentCategory, this.cityId);
                })
                .then(() => {
                    this.mainAffix();
                })
        },
        computed: {
            partnersList() {
                let partnersList = this.partnersCollection;
                // И по названию
                if (this.searchFilter) {
                    let search = this.searchFilter.replace(/([.,-])|\s/gi, "").toLowerCase();
                    partnersList = partnersList.filter(item => {
                        let normalize = item.title.replace(/([.,-])|\s/gi, "").toLowerCase();
                        return normalize.indexOf(search) !== -1;
                    });
                }

                return partnersList;
            },
        },
        methods: {
            fetchItems(category, city) {
                this.loading = true;
                let options = {
                    category,
                    city,
                }
                this.currentCategory = category;
                return new Promise((resolve, reject) => {
                    $.get('/partners-api', options).done((data) => {
                        this.storage = data.data;
                        this.partnersCollection = this.storage;
                        resolve();
                        this.loading = false;
                    });
                });
            },
            fetchCategories() {
                let _self = this;
                let myUrl = isDev ? '/partners-api/categories' : 'templates/data/bonus-partners.json';
                return new Promise((resolve, reject) => {
                    $.get(myUrl).done((data) => {
                        _self.categoriesList = JSON.parse(data);
                        _self.currentCategory = _self.categoriesList[0].id;
                        resolve();
                    });
                });

            },
            fetchCity () {
                let _self = this;
                let myUrl = isDev ? '/partners-api/cities' : 'templates/data/bonus-partners-city.json';
                return new Promise((resolve, reject) => {
                    $.get(myUrl).done((data) => {
                        _self.cityList = JSON.parse(data);
                        _self.cityId = _self.cityList[0].id;
                        resolve();
                    });
                });
            },
            mainAffix () {
                let offset = 24,
                    categoryWrap = $('.category__wrap'),
                    heightCategory = $('.category__block').height(),
                    heightContent = $('.categories__content').height(),
                    top = $('.category__block').offset().top - offset,
                    categoryBlockSpaces = parseInt(categoryWrap.css('padding-top')) + parseInt(categoryWrap.css('padding-bottom')) + offset;

                $('.category__block').css('min-height', heightCategory);

                if(heightCategory > heightContent) return;

                categoryWrap.affix({
                    offset: {
                        top: top,
                        bottom: function () {
                            return (this.bottom = $('.footer').height() + categoryBlockSpaces)
                        }
                    }
                })
            },
            updateCategory(category) {
                this.currentCategory = category.category_id;
            }
        }
    });
}
})();
