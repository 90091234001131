export default (() => {

  const carouselContainer = document.querySelector('.section__index__products_card');

  if(!carouselContainer) return;

  const carousel = new Swiper(carouselContainer.querySelector('.swiper-container'), {
    slidesPerView: 1,
    loop: true,
    watchOverflow: true,
    pagination: {
      el: carouselContainer.querySelector('.carousel__pagination'),
      type: 'bullets',
      clickable: true,
      modifierClass: 'carousel__pagination_',
      bulletClass: 'carousel__pagination_bullet',
      bulletActiveClass: 'carousel__pagination_bullet_active'
    },
    navigation: {
      nextEl: carouselContainer.querySelector('.btn__carousel_next'),
      prevEl: carouselContainer.querySelector('.btn__carousel_prev'),
    },
    breakpoints: {
      560: {
        slidesPerView: 2,
      },
      740: {
        slidesPerView: 3
      },
      980: {
        slidesPerView: 4
      }
    }
  });
  carousel.init();
  carousel.on('slideChangeTransitionEnd', () => {
      $(window).trigger('scroll');
  })
})();
