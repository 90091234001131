/* eslint-disable */

import '../lib/imask'

export default (() => {
	var formIIN = $('.form-control-iin');
	var formPHONE = $('.form-control-phone');
	var formNameLastname = $('.form-control-name_lastname');

	if(formNameLastname.length) {
		var maskNameLastname = IMask(formNameLastname[0], {
			mask: /^[a-zA-Zа-яА-Я]+(([a-zA-Zа-яА-Я][' -])?[a-zA-Zа-яА-Я]*)*$/
		});
	}

	if(formIIN.length) {
		var maskIIN = IMask(formIIN[0], {
			mask: '000000000000',
		});
	}

	if(formPHONE.length) {
		var placeholderContainer = $('.placeholders__container'),
			placeholderStatic = placeholderContainer.find('.placeholder-static'),
			placeholderDynamic = placeholderContainer.find('.placeholder-dynamic'),
			placeholderDynamicMask = '+7 (___) ___ __ __';

		formPHONE.each(function (i, el){
			var caseMask = IMask(el, {
				mask: '{+7 }(`CDNM',
				lazy: false,
				blocks: {
					CD: {
						mask: IMask.MaskedEnum,
						enum: ['700', '701', '702', '705', '707', '747', '708', '777', '778', '771', '775', '776'],
						lazy: true
					},
					NM: {
						mask: ')` 000-00-00',
						lazy: true,
					}
				}
			});
			caseMask.on('accept', function () {
				if(caseMask.value.length >= 5) {
					formPHONE.parent('.form-group-phone').addClass('changed')
				} else {
					formPHONE.parent('.form-group-phone').removeClass('changed')
				}
				dynamicMask(caseMask.value, placeholderDynamic);
			})
		})

		// placeholderDynamic.text(placeholderDynamicMask)
		formPHONE.on('focus', function () {
			$(this).parent('.form-group-phone').addClass('focused')
		});
		formPHONE.on('blur', function () {
			$(this).parent('.form-group-phone').removeClass('focused')
		});

		function dynamicMask (value, container) {
			var placeholderTransparent = container.find('.placeholder-transparent'),
				placeholderHint = container.find('.placeholder-hint');

				placeholderTransparent.text(value);

				placeholderHint.text(placeholderDynamicMask.slice(value.length, placeholderDynamicMask.length));
		}

		formPHONE.on('paste', function(e){
			e.preventDefault();
			var clipboardCurrentData = (e.originalEvent || e).clipboardData.getData('text/plain').replace(/[^0-9]/g, "").split('');

			if(clipboardCurrentData.length >= 11)
				clipboardCurrentData = clipboardCurrentData.slice(1, clipboardCurrentData.length)

			window.document.execCommand('insertText', false, clipboardCurrentData.join(''));
		});
  }

	var $formIin = $('.form-iin'),
		mainform = $('.mainform');

	if(!$formIin.length) return;

	$formIin.submit(function (e) {
		e.preventDefault();
		var $iinValue = $formIin.find('[name="iin"]').val()
		var url = $formIin.data('url').replace('{iin}', $iinValue);
		$.get(url).then(function (response) {
			$formIin.hide();
			if(response.result) {
				var stepSuccess = mainform.find('[data-step="found"]');
				if(response.text) stepSuccess.html(response.text + '<br>' + response.queue_number);
				stepSuccess.show();
			} else {
				mainform.find('[data-step="notfound"]').show();
			}
		})
	})
})()
