/* eslint-disable */

export default (() => {

  let currentPage = 1;
  $("._js_ajax_load").on('click', (e) => {
    e.preventDefault();
    const $self = $(e.target);
    const totalPages = $self.data('totalpages');
    const uploadTo = $self.data('upload-to');
    const elementFind = $self.data('element');
    const url = $self.data('url');

    if(currentPage >= totalPages) {
      $self.hide();
      return;
    }
    currentPage += 1;
    $self.addClass('is--loading');
    $.get(`${url}/${currentPage}`).done((html) => {
      const htmlElements = $(html).find(uploadTo);
      const elements = htmlElements.find(elementFind);

      $self.removeClass('is--loading');
      $(uploadTo).append(elements);

    });

  });

})();
