export default (() => {
  const carouselContainer = document.querySelectorAll('.section__common_cards_carousel');

  function getMaxOfArray(numArray) {
    return Math.max.apply(null, numArray);
  }
  function equalHeight (items, equalItems) {
    const arrayHeight = [];
    items.forEach(element => {
      const equalItem = element.querySelector('.card__title');
      if(!equalItem) return;
      arrayHeight.push(equalItem.offsetHeight)
    })
    const equalMaxHeight = getMaxOfArray(arrayHeight);
    $(equalItems).height(equalMaxHeight)
  }

  carouselContainer.forEach(element => {
    // const $self = $(element);
    const slides = element.querySelectorAll('.swiper-slide')
    const items = element.querySelectorAll('.swiper-slide').length;
    const itemTitle = element.querySelectorAll('.card__title');
    const carousel = new Swiper(element.querySelector('.swiper-container'), {
      init: false,
      // loop: items > 2,
      watchOverflow: items <= 2,
      // autoplay: {
      //   delay: 5000,
      // },
      watchSlidesProgress: true,
      watchSlidesVisibility: true,
      observer: true,
      observeParents: true,
      centeredSlides: true,
      centeredSlidesBounds: true,
      navigation: {
        nextEl: element.querySelector('.btn__carousel_next'),
        prevEl: element.querySelector('.btn__carousel_prev'),
      },
      slidesPerView: 1.6,
      slidesOffsetBefore: 16,
      slidesOffsetAfter: 70,
      spaceBetween: 16,
      speed: 700,
      // pagination: {
      //   el: element.querySelector('.carousel__pagination'),
      //   type: 'bullets',
      //   clickable: true,
      //   modifierClass: 'carousel__pagination_',
      //   bulletClass: 'carousel__pagination_bullet',
      //   bulletActiveClass: 'carousel__pagination_bullet_active'
      // },
      breakpoints: {
        680: {
          slidesPerView: 3,
          centeredSlides: false,
          centeredSlidesBounds: false,
          slidesOffsetBefore: 0,
          slidesOffsetAfter: 0,
        },
      },
      on: {
        // transitionEnd () {
        //   // equalHeight(slides, $self.find('.swiper-slide .card__title'));
        //   console.log(carousel)
        // },
        init() {
          equalHeight(slides, itemTitle);
          this.$wrapperEl[0].querySelectorAll('.swiper-slide').forEach(elem => {
            if(!elem.querySelector('.lazy')) return;

            window.lazy.triggerLoad(elem.querySelector('.lazy'));
          });
        },
      }
    });
    carousel.init();
  });
})();
