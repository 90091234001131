/* eslint-disable */

export default (() => {
	const $mainSelect = $('.main-select-dropdown')
	if($mainSelect.length) {
		const mainSelectItmes = $mainSelect.find('.selectric-items').find('li').length;
		if(mainSelectItmes >= 8) {
			$mainSelect.find('.selectric-items').css("height","302px");
		}
		$mainSelect.find('.selectric').on('click', () => {
			$mainSelect.find('.selectric-wrapper').toggleClass('selectric-open');
		});
  }



	var sectionTablet = $('.section__dropdown_tablet')
	var btnTitle = sectionTablet.find('.section__title')

	btnTitle.on('click', function () {
		if($( window ).width() <= 768) {
			$(this).closest('.section__dropdown_tablet').toggleClass('tablet__is_open')
		}
	});


  $("[data-anchor]").on('click', (event) => {
		if (this.hash !== "") {
		event.preventDefault();
		const {hash} = this;
		$('html, body').animate({
			scrollTop: $(hash).offset().top - 80
		}, 800, () => {
			window.location.hash = hash;
		});
		}
  });

})();
