/* eslint-disable */
export default (() => {
  var carouselContainer = document.querySelectorAll('.gallery-carousel');
  var carouselContainerItem = document.querySelector('.gallery-carousel');

  // eslint-disable-next-line
  if(!carouselContainerItem) return;
  var carouselThumbsContainer = carouselContainerItem.querySelector('.gallery-bottom');
  var carouselTopContainer = carouselContainerItem.querySelector('.gallery-top');
  var carouselThumbs = new Swiper(carouselThumbsContainer.querySelector('.swiper-container'), {
    // loop: items > 4,
    // watchOverflow: items <= 4,
    freeMode: true,
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
    observer: true,
    observeParents: true,
    slidesPerView: 4,
    spaceBetween: 8,
  });
  var carouselTop = new Swiper(carouselTopContainer.querySelector('.swiper-container'), {
    // loop: items > 4,
    observer: true,
    observeParents: true,
    thumbs: {
      swiper: carouselThumbs
    }
  });

  carouselTop.init();

  var carouselContainerModal = document.querySelector('.modal-gallery-carousel');
  var carouselModal = new Swiper(carouselContainerModal.querySelector('.swiper-container'), {
    speed: 700,
    loop: true,
    observer: true,
    observeParents: true,
    navigation: {
      nextEl: carouselContainerModal.querySelector('.btn__carousel_next'),
      prevEl: carouselContainerModal.querySelector('.btn__carousel_prev'),
    },
  });
  carouselModal.init();
  carouselTop.on('click',  (e) => {
    if(e.target.closest('.swiper-slide')) {
      $('.modal-gallery-carousel').modal('show');
    }
  });

  var myMap = document.querySelector('.product__map');
  if(!myMap) return;
  ymaps.ready(ymapsInitMain);
  function ymapsInitMain() {
    let $map = document.querySelector('.product__map_wrap'),
        zoom = parseInt($map.getAttribute('data-zoom'), 10),
        pin = $map.getAttribute('data-pin'),
        lat = $map.getAttribute("data-lat"),
        lng = $map.getAttribute("data-lng"),
        coords = [lng, lat],
        map = new ymaps.Map($map, {
          center: coords,
          zoom: zoom,
          controls: ['zoomControl']
        }),
        myPlacemark = new ymaps.Placemark(map.getCenter(), {}, {
          iconLayout: 'default#image',
          iconImageHref: pin,
          hideIconOnBalloonOpen: false
        });
    map.geoObjects.add(myPlacemark);
  }

})();
