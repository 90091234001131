/* eslint-disable */

import Vue from 'vue';
import XIRR from '../lib/XIRR'
import VueSlider from 'vue-slider-component'

export default (() => {
  var calculatorApp = $('#js_leasing_calculator');
	if (!calculatorApp.length) return;

	Vue.use(VueNumeric.default)
	var leasingProducts = getLeasingProducts() || [];
	if (!leasingProducts.length) return;

	var baseOptionsSlider = {
		tooltip: false,
		style: {
			"padding": "0",
			"top": "-2.5px"
		},
		dotSize: 23,
		bgStyle: {
			"backgroundColor": "transparent",
			"borderRadius": "0",
			"height": "3px"
		},

		sliderStyle: [{
				"backgroundColor": "#00805f",
			},
			{
				"backgroundColor": "#00805f",
			}
		],
		processStyle: {
			"background": "#00805f",
			"borderRadius": "0"
		}
	}
	var sliderOptionsSum = Object.assign({}, baseOptionsSlider, {
		interval: 5000
	});
	var sliderOptionsTerm = Object.assign({}, baseOptionsSlider, {});
	var sliderOptionsFee = Object.assign({}, baseOptionsSlider, {
		bgStyle: {
			"backgroundColor": '#bdbdbd',
			"borderRadius": "0",
			"height": "3px"
		},
	});

	new Vue({
		el: '#'+calculatorApp.get(0).id,
		delimiters: ['${', '}'],
		components: {
      VueSlider
		},
		data: {
			sliderSum: 0,
			sliderFee: 0,
			sliderTerm: 0,
			sliderOptionsTerm: sliderOptionsTerm,
			sliderOptionsSum: sliderOptionsSum,
			sliderOptionsFee: sliderOptionsFee,
			ticksLabelSum: [],
			ticksLabelTerm: [],
			separator: 'space',
			leasingProducts: leasingProducts,
			selectedLeasingProductId: null,
		},
		computed: {
			getSelectedProduct() {
				return this.leasingProducts.find((product) => {
					return product.id === this.selectedLeasingProductId;
				});
			},
			initialFeePercentToSum() {
				return this.sliderSum / 100 * this.sliderFee;
			},
			actualSum() {
				return this.sliderSum - this.initialFeePercentToSum;
			},
			calculate() {
				if(!this.getSelectedProduct) return 0;

				let ir = this.getSelectedProduct.rate / 100 / 12;
				let monthlyPayment = this.PMT(ir, this.sliderTerm, this.actualSum);

				return {
					monthlyPayment: Math.abs(monthlyPayment).toFixed(),
					totalSum: (Math.abs(monthlyPayment * this.sliderTerm)).toFixed()
				}
			}
		},
		watch: {
			getSelectedProduct(newProduct) {
				this.sliderOptionsSum.max = newProduct.sum.max;
				this.sliderSum = this.sliderOptionsSum.value = this.sliderOptionsSum.min = newProduct.sum.min;

				this.sliderTerm = this.sliderOptionsTerm.value = this.sliderOptionsTerm.max = newProduct.term.max;
				this.sliderTerm = this.sliderOptionsTerm.value = this.sliderOptionsTerm.min = newProduct.term.min;

				this.sliderFee = this.sliderOptionsFee.value = this.sliderOptionsFee.max = newProduct.initial_fee.max;
				this.sliderFee = this.sliderOptionsFee.value = this.sliderOptionsFee.min = newProduct.initial_fee.min;

				this.ticksLabelSum = [newProduct.sum.min, newProduct.sum.max];
				this.ticksLabelTerm = [newProduct.term.min, newProduct.term.max];
			}
		},
		methods: {
			PMT: function(rate, nper, pv, fv, type) {
				if (!fv) fv = 0;
				if (!type) type = 0;

				if (rate == 0) return -(pv + fv)/nper;

				var pvif = Math.pow(1 + rate, nper);
				var pmt = rate / (pvif - 1) * -(pv * pvif + fv);

				if (type == 1) {
					pmt /= (1 + rate);
				};

				return pmt;
			}
		},
		mounted() {
			$('#credit_type').selectric().on('change', (e) => {
				this.selectedLeasingProductId = +$(e.target).val();
			}).trigger('change');
		}
	})
})();
