/* eslint-disable */

import { getCookie, setCookie } from '../lib/utils'

export default (() => {
	$('.select select').not('#credit_type').selectric({
		onInit: function (event, element, selectric) {
			const select = element.$element;
			const placeholder = select.data('placeholder');
			const wrapper = select.closest('.selectric-wrapper');
			const label = wrapper.find('.label');
			if ( label.text() !== placeholder ) {
				setTimeout(() => {
					label.text(placeholder);
				}, 0)
			}
			if (select.hasClass('select-search')) {
				var searchFieldPlaceholder = select.data('search-placeholder')?select.data('search-placeholder'):''
				var itemList = element.elements.items;
				itemList.append('<div class="form-group search-form-selectric"><input class="form-control search-input-selectric" placeholder="'+searchFieldPlaceholder+'"/></div>');
				$('.search-input-selectric').on('click', function (e) {
					e.stopImmediatePropagation();
				});
			}
		},
		onOpen: function (event, element, selectric) {
			if (!element.$element.hasClass('select-search')) return;
			$('.search-input-selectric').focus();
		},
		onClose: function (event, element, selectric) {
			if (!element.$element.hasClass('select-search')) return;
			$('.search-input-selectric').val('').trigger('input');

		}
	});


	$('.select').on('input', '.search-input-selectric', (e) => {
		const $self = $(e.target);
		const list = $self.closest('.selectric-items').find('.selectric-scroll ul');

		const search = $self.val().replace(/([.,-])|\s/gi, "").toLowerCase();
		list.find('li').each((i, elem) => {
			const normalize = $(elem).text().replace(/([.,-])|\s/gi, "").toLowerCase();
			normalize.indexOf(search) !== -1 ? ($(elem).show()) : ($(elem).hide());
		});
	})



	const citySelectedIndex = getCookie('city_selected_index');

	if (citySelectedIndex) {
		$('._js_change_city').prop('selectedIndex', citySelectedIndex).selectric('refresh');
	} else {
		setCookie('city_selected_index', 0, {
			expires: 3600 * 365,
			path: "/"
		});
	}
	window.updateCity = function (id, lat, lng, cb) {

		setCookie('city_id', id, {
			expires: 3600 * 365,
			path: "/"
		});

		setCookie('city_lat', lat, {
			expires: 3600 * 365,
			path: "/"
		});

		setCookie('city_long', lng, {
			expires: 3600 * 365,
			path: "/"
		});

		if (cb != undefined)
			cb();

	}
})()
