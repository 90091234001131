/* eslint-disable */

export default (() => {
    $('.modal-leaderships').on('show.bs.modal', (e) =>{
      const $item = $(e.relatedTarget).closest('.lead__item');
      const $modal = $(e.target);
      const pathImg = $item.find('.img').data('background-image');
      const headHtml = $item.find('.item__head').html();
      const contentHtml = $item.find('.content').html();

      $modal.find('.block-body-wrap').html(contentHtml);
      $modal.find('.modal-left .media img').attr('src', pathImg);
      $modal.find('.block-content-head-wrap').html(headHtml);
    });
})();
