export default (() => {
  const carouselContainer = document.querySelector('.media__slider-inner-page');

  if(!carouselContainer) return;

  const carousel = new Swiper(carouselContainer.querySelector('.swiper-container'), {
    speed: 700,
    effect: 'fade',
    loop: true,
    fadeEffect: {
      crossFade: true
    },
    navigation: {
      nextEl: carouselContainer.querySelector('.btn__carousel_next'),
      prevEl: carouselContainer.querySelector('.btn__carousel_prev'),
    },
    on: {
      init () {
        carouselContainer.classList.add('init');
      },
      transitionEnd () {
        window.lazy.observe();
      }
    },
  });
  carousel.init();
})();
