export default (() => {

  const carouselContainer = document.querySelectorAll('.section__carousel');
  if(!carouselContainer) return;

  function getMaxOfArray(numArray) {
    return Math.max.apply(null, numArray);
  }
  function equalHeight (items, equalItems) {
    const arrayHeight = [];
    items.forEach(element => {
      const equalItem = element.querySelector('.item__title');
      arrayHeight.push(equalItem.offsetHeight)
    })
    const equalMaxHeight = getMaxOfArray(arrayHeight);
    $(equalItems).height(equalMaxHeight)
  }
  carouselContainer.forEach(element => {
    const $self = $(element);
    const slides = element.querySelectorAll('.swiper-slide')
    const slidesCount = slides.length;
    const itemTitle = element.querySelectorAll('.item__title');
    const carousel = new Swiper(element.querySelector('.swiper-container'), {
      slidesPerView: 1,
      loop: slidesCount > 1,
      watchOverflow: slidesCount === 1,
      navigation: {
        nextEl: element.querySelector('.btn__carousel_next'),
        prevEl: element.querySelector('.btn__carousel_prev'),
      },
      breakpoints: {
          480: {
            slidesPerView: 2,
            loop: slidesCount > 2,
            watchOverflow: slidesCount <= 2,
          },
          768: {
            slidesPerView: 4,
            loop: slidesCount > 4,
            spaceBetween: 16,
            watchOverflow: slidesCount < 4,
          }
      },
      on: {
        transitionEnd () {
          window.lazy.observe();
          equalHeight(slides, $self.find('.swiper-slide .item__title'));
        },
        init () {
          equalHeight(slides, itemTitle);
        }
      }
    });
    carousel.init();
  });
})();
