
export default (() => {


  const carouselContainer = document.querySelector('.partners-carousel');

  // eslint-disable-next-line
  if(!carouselContainer) return;

  const items = carouselContainer.querySelectorAll('.swiper-slide').length;
  const carousel = new Swiper(carouselContainer.querySelector('.swiper-container'), {
    loop: items > 3,
    watchOverflow: items <= 3,
    // autoplay: {
    //   delay: 5000,
    // },
    watchSlidesProgress: true,
    watchSlidesVisibility: true,
    observer: true,
    observeParents: true,
    navigation: {
      nextEl: carouselContainer.querySelector('.btn__carousel_next'),
      prevEl: carouselContainer.querySelector('.btn__carousel_prev'),
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
    },
    slidesPerView: 1,
    spaceBetween: 0,
    breakpoints: {
      400: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      580: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
      768: {
        slidesPerView: 4,
        spaceBetween: 16,
      },
      920: {
        slidesPerView: 5,
        spaceBetween: 16,
      },
      1125: {
        slidesPerView: 6,
        spaceBetween: 16,
      },
    }
  });
  carousel.init();
})();
