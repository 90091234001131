/* eslint-disable */

import Vue from 'vue';

export default (() => {
if ($('#page-exchange-rates').length) {
  let exchangeRates = new Vue({
      el: '#page-exchange-rates',
      delimiters: ["${", "}"],
      data: {
          //Выбранная дата
          dateSelected: null,
          //Выбранный тип курса
          typeSelected: null,
          //Здесь храним данные не изменяя их
          storage: [],
          //храним измененые данные
          rateCollection: [],
          //храним Курсы текущего дня
          collectionCurrentDay: {},
          //храним Курсы предыдущего дня
          collectionPrevDay: {},
          dateTitleCurrentDay: '',
          dateTitlePrevDay: '',
          //Настройки календаря, используется плагин https://eonasdan.github.io/bootstrap-datetimepicker/
          datepicker: {
              locale: 'ru',
              format: 'DD.MM.YYYY',
              maxDate: moment(),
              minDate: moment().subtract(1, 'year'),
          },
          //Выбранный филиал
          branchSelected: '050201', // Алматинский ОФ
          // Курсы валют в разрезе филиалов
          branchesData: [],
          branchesRateCollection: []
      },
      watch: {
          typeSelected(val) {
              if (this.typeSelected == 'privatePersons') {
                  this.setBranches();
              } else {
                  this.collectionCurrentDay = this.rateCollection[0][this.typeSelected];
                  this.collectionPrevDay = this.rateCollection[1][this.typeSelected];
                  this.dateTitleCurrentDay = this.rateCollection[0].dateTitle;
                  this.dateTitlePrevDay = this.rateCollection[1].dateTitle;
              }

          },
          branchSelected(val) {
              this.setBranches();
          }
      },
      created: function () {
          let self = this;
          $.ajax({
              url: "https://back.halykbank.kz/common/currency-history",
              type: "GET",
              dataType: "json"
          }).done((r) => {
              if (r.result) {
                  self.init(r);
              } else {
                  alert('Ошибка в получение данных');
              }
          });

          $.ajax({
              url: "/api/branches-ccy",
              type: "GET",
              dataType: "json"
          }).done((r) => {
              if (r.result) {
                  self.initBranches(r);
              } else {
                  alert('Ошибка в получение данных по курсам в разрезе филиалов');
              }
          });
      },
      methods: {
          init(data) {
              this.storage = data.data;

              Object.keys(this.storage.currencyHistory).forEach((element) => {
                  this.rateCollection.push(this.storage.currencyHistory[element])
              });
              this.typeSelected = Object.keys(this.storage.currencyTypes)[0];
              this.$refs.exchangeRatesWrap.classList.remove('loading');
          },
          initBranches(data) {
              this.branchesData = data.data;

              Object.keys(this.branchesData.currencyHistory).forEach((element) => {
                  this.branchesRateCollection.push(this.branchesData.currencyHistory[element])
              });
              this.$refs.exchangeRatesWrap.classList.remove('loading');
          },
          setBranches() {
              this.collectionCurrentDay = this.branchesRateCollection[0].branches[this.branchSelected];
              this.collectionPrevDay = this.branchesRateCollection[1].branches[this.branchSelected];
              this.dateTitleCurrentDay = this.branchesRateCollection[0].dateTitle;
              this.dateTitlePrevDay = this.branchesRateCollection[1].dateTitle;
          }
      }
  });
}

if ($('#_js_currency_widget').length) {
    Vue.use(VueNumeric.default)

    let currencyWidget = new Vue({
        el: '#_js_currency_widget',
        delimiters: ["${", "}"],
        data: {
            loading: true,
            //Здесь храним данные не изменяя их
            storage: [],
            //храним измененые данные
            rateCollection: [],
            currencies: [{
                    id: 1,
                    name: 'KZT',
                    symb: '₸'
                },
                {
                    id: 2,
                    name: 'USD',
                    symb: '$'
                },
                {
                    id: 3,
                    name: 'EUR',
                    symb: '€'
                },
                {
                    id: 4,
                    name: 'RUB',
                    symb: '₽'
                },

            ],
            currencyFrom: {
                id: 1,
                name: 'KZT',
                symb: '₸'
            },
            currencyTo: {
                id: 2,
                name: 'USD',
                symb: '$'
            },
            separator: 'space',
            currencySumFrom: 1000,

        },
        watch: {
            currencyFrom(newValue, oldValue) {
                if (newValue.id == this.currencyTo.id) {
                    this.currencyTo = oldValue
                }
            },
            currencyTo(newValue, oldValue) {
                if (newValue.id == this.currencyFrom.id) {
                    this.currencyFrom = oldValue
                }
            }
        },
        computed: {
            currencySumTo() {
                let rates = this.findCurrency(this.rateCollection, ['RUB', 'USD', 'EUR']);
                let total = 0;
                if (rates.length) {
                    if (this.currencyFrom.name == 'KZT') {
                        let findRate = rates.find(x => x.name == this.currencyTo.name);
                        total = this.currencySumFrom / findRate.sell
                    } else if (this.currencyTo.name == 'KZT') {
                        let findRate = rates.find(x => x.name == this.currencyFrom.name);
                        total = this.currencySumFrom * findRate.buy
                    } else {
                        let findRateFrom = rates.find(x => x.name == this.currencyFrom.name);
                        let findRateTo = rates.find(x => x.name == this.currencyTo.name);
                        total = this.currencySumFrom * findRateFrom.buy / findRateTo.sell
                    }
                }

                return new Intl.NumberFormat().format(total)
            }
        },
        mounted() {
            let self = this;
            $.ajax({
                url: "https://back.halykbank.kz/common/currency-history",
                type: "GET",
                dataType: "json"
            }).done((r) => {
                if (r.result) {
                    self.init(r);
                } else {
                    alert('Ошибка в получение данных');
                }
            });
        },
        methods: {
            findCurrency(rates, filters) {
                let keys = Object.keys(rates);
                let filteredArray = [];
                for (let i = 0; i < filters.length; i++) {
                    for (let j = 0; j < keys.length; j++) {
                        let search = keys[j].search(filters[i]);
                        if (search !== -1) {
                            let obj = rates[keys[j]];
                            obj.name = filters[i];
                            filteredArray.push(obj);
                        }
                    }
                }
                return filteredArray;
            },
            init(data) {
                this.loading = false;
                this.storage = data.data.currencyHistory;
                Object.keys(this.storage).forEach((element) => {
                    this.rateCollection.push(this.storage[element])
                });

                this.rateCollection = this.rateCollection[0].privatePersons;

            }
        }
    });
}
})();
