/* eslint-disable */

import Vue from 'vue'
import { formatAmount, format, prettyDate } from './utils'

export default (() => {
  Vue.filter('format', (value) => {
      if (!value) return '';
      return format(value);
  });

  Vue.filter('formatAmount', (value, arr) => {
      if (!value) return '';
      return formatAmount(value, arr);
  });

  Vue.filter('prettyDate', (value, monthes, years) => {
      if (!value) return '';
      return prettyDate(value, monthes, years);
  });

  Vue.filter('declOfNum', (number, titles) => {
    if (!number && !titles) return ''
    const cases = [2, 0, 1, 1, 1, 2];
    return `${number} ${titles[ (number%100>4 && number%100<20)? 2 : cases[(number%10<5)?number%10:5] ]}`;
  });

})();
