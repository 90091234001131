// global file
import './store'
import 'alpinejs';
import 'bootstrap/dist/js/bootstrap'
import './modules/lozad'
import './lib/wrapper-components'
// components file
import './modules/bonus-partners'
import './modules/knowledge-base'
import './modules/search'
import './modules/section-exchange-rates'
// import './modules/sidebar-mobile'
import './modules/team-lead'
import './modules/video-item'
import './modules/forms'
import './modules/form-valid'
import './modules/load-more'
import './modules/modal'
import './modules/select'
import './lib/vue-filters'
import './modules/landing'
import './modules/halyk-club'
import './modules/deposit-calculator'
import './modules/credit-calculator'
import './modules/map-app'
import './modules/pomoika'
import './modules/scroll-bar'


import './carousel/info-inner-page'
import './carousel/media-slider'
import './carousel/section-carousel'
import './carousel/section-index-products-card'
import './carousel/partners-carousel'
import './carousel/halyk-club-carousel'
import './carousel/common-carousel'

import './modules/leasing-calculator'
import './modules/lesing-partners'
import './modules/products'
