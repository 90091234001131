/* eslint-disable */

export default (() => {
  $('.popup-info').on('click', function () {
    if($(window).width() <= 768) {
      $(this).toggleClass('is--open')
    }
	});
  var mySection = $('.section-landing');
  if(mySection.length > 0) {
    var btn = $('.btn-scroll-landing')
    mySection.each(function () {
      var $el = $(this);
      $el.not('.fadeInUp').waypoint(function(direction) {
        if($el.hasClass('fadeInUp')) return;
        $el.addClass('fadeInUp');
        if($el[0].id == btn.attr("href").replace(/#/, '')) {
          btn.hide();
        }
        if($el.hasClass('hand-left')) {
          btn.hide();
        }
      },{
        offset: function() {
          return Waypoint.viewportHeight() - (this.element.clientHeight / 2);
        }
      })
    });
    btn.on('click', function(event) {
      if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') || location.hostname == this.hostname) {
        var target = $(this.hash);

        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
        if (target.length) {
          $('html,body').animate({
            scrollTop: target.offset().top
          }, 1000);
          $(this).hide();
          return false;
        }
      }
    });
  }
	var mediaSlider = $('.media-slider-scroll');
  var allSumHeight = 0;
  if(!mediaSlider.length) return;
  var timeline = anime.timeline({
    opacity: 1,
    autoplay: false,
    delay: 1000,
  });
  timeline.pause;
  $(".item-media").each(function( index ) {
    timeline.add({
      targets: $(this)[0],
      opacity: 1,
      delay: 1000,
    })
    var media = $(this).find('.media').outerHeight(true) || 0;
    var title = $(this).find('.title').outerHeight(true) || 0;
    var desc = $(this).find('.desc').outerHeight(true) || 0;
    allSumHeight = media + title + desc;

    if(allSumHeight > $(this).parents('.media-slider-scroll').outerHeight(true)) {
      mediaSlider.css({'min-height' : allSumHeight +'px'});
    }
  });
  var targetScrollMagic = window.innerWidth >= 960 ? ".section-media-slider-scroll" : ".media-slider-scroll";
  var controller = new ScrollMagic.Controller();
  new ScrollMagic.Scene({
    triggerElement: ".section-media-slider-scroll",
    triggerHook: "onLeave",
    duration: "100%"
  })
  .setPin(".section-media-slider-scroll")
  .addTo(controller)
  .on("progress", function (event) {
    timeline.seek(event.progress * timeline.duration);
  });
})();
