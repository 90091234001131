/* eslint-disable */

import { declOfNum, formatAmount, format } from '../lib/utils'
import find from 'lodash/find'

export default (() => {

  if(!$('._js_deposit_calculator').length) return;
  var DepositCalculator = function () {
      //Получение типов депозита
      var monthsLang = ['месяц', 'месяца', 'месяцев'],
          currencySuffix = { USD: 'USD', EUR: 'EUR', KZT: 'KZT' },
          realTermTick = [],
          products = getDepositProducts(),
          product = products[0],
          NOMINAL_RATE = 0,
          EFFECTIVE_RATE = 0,
          AMOUNT_DEPOSIT = product.currency[0].months[0].min,
          DEPOSIT_DELAY = product.currency[0].months[0].month,
          _JS_SLIDER_SUM  = null,
          _JS_SLIDER_TERM = null,
          _JS_INPUT_SUM = null,
          _JS_NOMINAL_RATE_TEXT = $('._js_nominal_rate .t'),
          _JS_EFFECTIVE_RATE_TEXT = $('._js_effective_rate .t'),
          _JS_REWARD_TEXT = $('._js_reward .n'),
          _JS_TOTAL_SUM_TEXT = $('._js_total_sum .n'),

          _JS_TEXT_TERM = $('._js_text_term'),
          DEPOSIT_SELECT = $('#deposit_type');

      setData(products[0].currency[0]);
      getData(products[0].currency[0]);
      //Заполнение типов депозита
      (function initSelectDepositType() {
          if(products.length <= 1) return;

          for(var productIndex in products) {
              var product = products[productIndex];
              DEPOSIT_SELECT.append('<option value="'+ product.id +'">' + product.name + '</option>');
          }
          DEPOSIT_SELECT.selectric('refresh').on('change', function() {
              var indexSelected = +$(this).val();
              var result = find(products, {id: indexSelected});
              initCurrencyNav(result.currency);
              setData(result.currency[0])
              getData(result.currency[0]);

          });
      })();

      //Формирование выборки валюты
      function initCurrencyNav(arr) {
          var _js_change_deposit_currency = $('._js_change_deposit_currency');
          var html = '';
          for(var currencyIndex in arr) {
              var currencyItem = arr[currencyIndex];
              html += '<div class="radio">'+
                          '<input type="radio" id="deposit_currency_'+currencyItem.name+'" '+(+currencyIndex == 0 ? "checked": "" )+' value="'+currencyItem.name+'" name="deposit_currency">'+
                          '<label for="deposit_currency_'+currencyItem.name+'">'+currencyItem.name+'</label>'+
                      '</div>';
          }
          _js_change_deposit_currency.html(html);
          _js_change_deposit_currency.find('[type="radio"]').on('change', function () {
              var result = find(arr, {name: $(this).val()});
              setData(result);
              getData(result);
          })

      };

      function setData(arr) {
          var arrMonths = arr.months,
              step_sum = { KZT: 50000, USD: 100, EUR: 100 },
              termTicks = [], termTicksLabels = [], sumTicksLabels = [];

          realTermTick = [];

          NOMINAL_RATE = arrMonths[0].nominal_rate;
          EFFECTIVE_RATE = arrMonths[0].effective_rate
          DEPOSIT_DELAY = arrMonths[0].month;
          _JS_TEXT_TERM.text(arrMonths[0].month+ ' ' +declOfNum(arrMonths[0].month, monthsLang));


          for(var monthsIndex in arrMonths) {
              var monthItem = arrMonths[monthsIndex];
              termTicks.push(monthsIndex);
              realTermTick.push(monthItem.month);
              termTicksLabels.push(monthItem.month + 'мес.');
              if(monthsIndex == 1) {
                  sumTicksLabels.push(formatAmount(monthItem.min));
              }
              if(monthsIndex == arrMonths.length - 1) {
                  sumTicksLabels.push(formatAmount(monthItem.max));
              }
          }

          // Если слайдер существует удаляем его и заного инициализируем
          if(_JS_SLIDER_TERM) {
              _JS_SLIDER_TERM.slider('destroy');
          }

          if(_JS_SLIDER_SUM) {
              _JS_SLIDER_SUM.slider('destroy');
          }
          _JS_SLIDER_SUM = $("#_js_slider_sum").slider({
              min: arrMonths[0].min,
              value: 1,
              step: step_sum[arr.name],
              max: arrMonths[0].max,
              ticks_labels: sumTicksLabels,
          }).off('slide change').on('slide change', function (e) {
              var value = e.target.value;
              _JS_INPUT_SUM.unmaskedValue = String(value);
              _JS_INPUT_SUM.updateValue();
          });

          _JS_SLIDER_TERM = $("#_js_slider_term").slider({
              min: 1,
              value: 1,
              max: arrMonths.length,
              ticks: termTicks,
              ticks_labels: termTicksLabels,
          }).off('change').on('change', function (ev) {
              var monthsCount = getRealTick(+ev.target.value - 1);
              var val = monthsCount+ ' ' +declOfNum(monthsCount, monthsLang);
              var depositCurrency = $('._js_change_deposit_currency').find('input:checked').val(),
                  depositType = DEPOSIT_SELECT.val(),
                  result = find(products, {id: +depositType});

              result = find(result.currency, {name: depositCurrency});
              result = find(result.months, {month: monthsCount});
              NOMINAL_RATE = result['nominal_rate'];
              EFFECTIVE_RATE = result['effective_rate'];
              _JS_NOMINAL_RATE_TEXT.text(NOMINAL_RATE);
              _JS_EFFECTIVE_RATE_TEXT.text(EFFECTIVE_RATE);
              _JS_TEXT_TERM.text(val);
          });

          _JS_REWARD_TEXT.siblings('.t-currency').text(currencySuffix[arr.name])
          _JS_TOTAL_SUM_TEXT.siblings('.t-currency').text(currencySuffix[arr.name])
          if(_JS_INPUT_SUM) {
              _JS_INPUT_SUM.destroy();
          }
          _JS_INPUT_SUM = new IMask($("#_js_input_sum").get(0), {
              mask: 'num {'+currencySuffix[arr.name]+'}',
              lazy: false,
              blocks: {
                  num: {
                      mask: Number,
                      thousandsSeparator: ' '
                  }
              }
           })
          _JS_INPUT_SUM.unmaskedValue = String(arrMonths[0].min);
          _JS_INPUT_SUM.updateValue();
          _JS_NOMINAL_RATE_TEXT.text(NOMINAL_RATE);
          _JS_EFFECTIVE_RATE_TEXT.text(EFFECTIVE_RATE);

          function getRealTick(int) {
              return realTermTick[int];
          }
          $("#_js_input_sum").off('blur').on('blur', function (e) {
              var val = parseInt($(this).val().replace(new RegExp(' ', 'g'), ''));
              if(val < arrMonths[0].min || isNaN(val)) {
                  val = arrMonths[0].min;
              } else if(val > arrMonths[0].max) {
                  val = arrMonths[0].max;
              }
              $("#_js_slider_sum").slider('setValue', val).trigger('change');
          })
      }

      function getData(arr) {
          var rewardTotal = 0,
              current_day = new Date();

          AMOUNT_DEPOSIT = parseFloat(arr.months[0].min);

          _JS_SLIDER_SUM.on('change', function (e) {
              AMOUNT_DEPOSIT = parseInt(e.target.defaultValue);
              calculate();
          });

          _JS_SLIDER_TERM.on('change', function (e) {
              DEPOSIT_DELAY = realTermTick[e.value.newValue - 1];
              calculate();
          });

          function calculate () {

              var items = [],
                  prevMonthDetails,
                  theMonth = current_day.getMonth(),
                  theYear = current_day.getFullYear();

              rewardTotal = 0;

              for (var i = 0; i < DEPOSIT_DELAY; i++) {
                  if (theMonth>11) {
                      theYear++;
                      theMonth = 0;
                  }
                  var theMonthFirstDay = new Date(theYear, theMonth+1, 1);
                  var theMonthDetailed = getDetailedMonth(i, theMonthFirstDay, AMOUNT_DEPOSIT);
                  rewardTotal += theMonthDetailed.rewardAmount;
                  items.push(theMonthDetailed);

                  prevMonthDetails = theMonthDetailed;
                  theMonth++;
              }

              _JS_REWARD_TEXT.text(format(parseFloat(rewardTotal.toFixed())));
              _JS_TOTAL_SUM_TEXT.text(format(parseFloat(AMOUNT_DEPOSIT.toFixed()) + parseFloat(rewardTotal.toFixed())));
          };

          //Расчитать детальную информацию по месячным начислениям
          function getDetailedMonth(monthIndex, monthFirstDay, depositAmount) {
              var theAnualKoef = NOMINAL_RATE / 100;
              //Получить количество суток в текущем месяце
              var theMonthDays = new Date(monthFirstDay.getFullYear(), monthFirstDay.getMonth(), 0).getDate();

              //Расчитать вознаграждение, которое будет начислено в текущем месяце
              var theMonthReward = ((depositAmount+rewardTotal)*theAnualKoef/360*30);

              var data = {
                  month: monthIndex + 1,
                  firstDay: monthFirstDay,
                  periodDays:theMonthDays,
                  rewardAmount:theMonthReward
              };
              return data;
          }



          calculate();

      }
      initCurrencyNav(product.currency);
  }
  DepositCalculator();
})();
