export default (() => {
  const container = document.querySelectorAll('.scroll-bar');

  // eslint-disable-next-line
  if(!container) return;

  container.forEach(element => {
  // eslint-disable-next-line
    const ps = new PerfectScrollbar(element, {
      wheelSpeed: 2,
      wheelPropagation: true,
      minScrollbarLength: 20
    });

  });
})();
