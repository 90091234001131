export default (() => {

  const carouselContainer = document.querySelector('.media__slider_top');

  if(!carouselContainer) return;

  const slides = carouselContainer.querySelectorAll('.swiper-slide');
  const slidesCount = slides.length;

  const carousel = new Swiper(carouselContainer.querySelector('.swiper-container'), {
    speed: 700,
    effect: 'fade',
    loop: slidesCount > 1,
    autoplay: {
        delay: 5000,
    },
    fadeEffect: {
      crossFade: true
    },
    navigation: {
      nextEl: carouselContainer.querySelector('.btn__carousel_next'),
      prevEl: carouselContainer.querySelector('.btn__carousel_prev'),
    },
    pagination: {
      el: carouselContainer.querySelector('.carousel__pagination'),
      type: 'bullets',
      clickable: true,
      modifierClass: 'carousel__pagination_',
      bulletClass: 'carousel__pagination_bullet',
      bulletActiveClass: 'carousel__pagination_bullet_active'
    },
    on: {
      init () {
        carouselContainer.classList.add('init');
        if(slidesCount > 1) {
          carouselContainer.classList.add('init-multi');
        }
      },
      transitionEnd () {
        window.lazy.observe();
      }
    },
  });
  carousel.init();
})();
