/* eslint-disable */

export default (() => {
  function setModalMaxHeight(element) {
    const content = element.find('.modal-content');
    const borderWidth = content.outerHeight() - content.innerHeight();
    const dialogMargin = $(window).width() < 768 ? 20 : 60;
    const windowsHeight = $(window).height() - (dialogMargin + borderWidth);
    const headerHeight = element.find('.modal-header').outerHeight() || 0;
    const footerHeight = element.find('.modal-footer').outerHeight() || 0;
    const maxHeight = windowsHeight - (headerHeight + footerHeight);

    if(maxHeight >= content.outerHeight()) return;
    content.css({
      'overflow': 'hidden'
    });

    element.find('.modal-body').css({
      'height': maxHeight
    });

    if(!element.find('.scroll-block'.length)) return;

    const	blockContent = element.find('.scroll-block-main');
    const	scrollxPaddingTop = parseInt(element.find('.scroll-x').css('padding-top')) || 0;
    const	blockContentPadding = parseInt(blockContent.css('padding-top')) + parseInt(blockContent.css('padding-bottom'))
    const	modalBody = element.find('.modal-body').height();
    const	contentHead = blockContent.find('.block-content-head').outerHeight() + parseInt(blockContent.find('.block-content-head').css('margin-bottom'));
    const	scrollHeight = modalBody - contentHead - blockContentPadding - scrollxPaddingTop;

      blockContent.find('.scroll-block').css({'height' : scrollHeight});
  }
  $('.modal').on('shown.bs.modal', (e) => {
    setModalMaxHeight($(e.target));
  });
  $('.modal').on('hide.bs.modal', (e) => {
    $(e.target).find('.modal-body').css({'height' : 'auto'});
  });

  $(window).resize(() => {
    if ($('.modal.in').length !== 0) {
      setModalMaxHeight($('.modal.in'));
    }
  });

	const $modalCurrency = $('.modal-currency')
	if($modalCurrency.length) {
		$modalCurrency.find('.js-btn-modal-currency').on('click', () => {
			$modalCurrency.find('.js-modal-currency-one').hide();
			$modalCurrency.find('.js-modal-currency-two').show();
	  });
  }

})();
