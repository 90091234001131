/* eslint-disable */

import Vue from 'vue';
import { getCookie, setCookie } from './utils'

export default (() => {
Vue.directive('click-outside', {
    bind: function (el, binding, vnode) {
        vnode.context.event = function (event) {
            if (!(el == event.target || el.contains(event.target))) {
                vnode.context[binding.expression](event);
            }
        };
        document.body.addEventListener('click', vnode.context.event)
    },
    unbind: function (el, binding, vnode) {
        document.body.removeEventListener('click', vnode.context.event)
    },
});

Vue.component('dropdown', {
    template: `<div class="component-dropdown" :class="{'dropdown-opened': showOptions}" v-click-outside="hide"  @click="showOptions = !showOptions">
          <div class="component__select">
              <span class="component__select--name">{{value ? value.symb : 'Select'}}</span>
          </div>
          <ul class="component__select-options" v-if="showOptions" >
              <li class="select--option" :class="{selected: option.id == value.id}" v-for="option in options" @click="selectOption(option)">
                  <label> <input type="checkbox" :value="option"/> {{option.symb}}</label>
              </li>
          </ul>
      </div>`,

    methods: {
        selectOption: function (option) {
            this.$emit('input', option)
        },
        hide: function ()  {
            this.showOptions = false
        }
    },
    data: function () {
        return {
            showOptions: false
        }
    },
    props: ['options', 'value']
});



//Компонент обертка для datepicker, используется плагин https://eonasdan.github.io/bootstrap-datetimepicker/
Vue.component('datepicker', {
    props: ['options'],
    template: `<div class="input-group">
                    <div class="input-group-addon">
                        <input type="text" class="form-control form-control-normal" />
                        <i class="icon icon-calendar"></i>
                    </div>
                </div>`,
    mounted: function () {
        var vm = this;
        $(vm.$el).find('input')
            .datetimepicker(this.options)
            .on('dp.change', function () {
                var value = $(this).val();
                vm.$emit('input', value)
            }).trigger('dp.change');
    },
    watch: {
        options: {
            handler: function (val) {
                var input = $(this.$el).find('input');
                input.data("DateTimePicker").destroy();
                input.datetimepicker(val);
            },
            deep: true
        }
    },
    destroyed: function () {
        $(this.$el).find('input').off().data("DateTimePicker").destroy();
    }
});

//Компонент обертка для селекта, используется плагин http://selectric.js.org/
Vue.component('selectric', {
    props: ['value'],
    template: `
        <select v-bind:value="value">
            <slot></slot>
        </select>`,
    mounted: function () {
        var vm = this;

        $(vm.$el).selectric()
            .trigger('change')
            .on('change', function () {


                vm.$emit('input', this.value);
            });
    },
    destroyed: function () {
        $(this.$el).off().selectric('destroy');
    }
});

Vue.component('selectric-city', {
    props: ['value'],
    template: `
        <select :value="value">
            <slot></slot>
        </select>`,
    mounted: function () {
        var vm = this;
        var citySelectedIndex = getCookie('city_selected_index');

        $(vm.$el).selectric({
            onInit: function (el, obj) {
                vm.$emit('input', JSON.parse(el.options[citySelectedIndex ? citySelectedIndex : 0].value));
            },
        }).on('change', function () {
            vm.$emit('input', JSON.parse($(this).val()));
            vm.$emit('change');
        });

        // console.log(citySelectedIndex);
        if (citySelectedIndex) {
            $(vm.$el).prop('selectedIndex', citySelectedIndex).selectric('refresh');
        } else {
            setCookie('city_selected_index', 0, {
                expires: 3600 * 365,
                path: "/"
            });
        }
    },
    destroyed: function () {
        $(this.$el).off().selectric('destroy');
    }
});
})();
